
import React, { Component } from "react";

import L from "leaflet";

import IconLocation from "./Icons/IconLocation";

class Location extends Component {

    state = {
        watchLocation: false,
        location: null,
        distance: null
    };

    onLocationFound = e => {
        if (this.state.location) {
            this.props.map.removeLayer(this.state.location);
        }

        var radius = e.accuracy / 2;

        const location = L.circle(e.latlng, radius, {
            className: 'locationCircle'
        });

        location.addTo(this.props.map);

        const distance = Math.round(e.latlng.distanceTo(this.props.homePosition));

        this.setState({
            location: location,
            distance: distance
        });

    };

    onLocationError = e => {
        this.setState({
            watchLocation: false
        });
    }



    toggleLocate = () => {
        if (this.state.watchLocation) {

            if (this.state.location) {
                this.props.map.stopLocate();
                this.props.map.removeLayer(this.state.location);
            }

            this.setState({
                watchLocation: false,
                location: null,
                distance: null
            });
        } else {
            this.props.map.locate({
                setView: false,
                maxZoom: 16,
                enableHighAccuracy: true,
                watch: true
            });

            this.setState({
                watchLocation: true
            });
        }
    };

    initMap = () => {
        this.props.map.on("locationfound", this.onLocationFound);
        this.props.map.on('locationerror', this.onLocationError);
    };

    componentDidMount() {
        if (this.props.map) {
            this.initMap();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.map !== this.props.map) {
            if (this.props.map) {
                this.initMap();
            }
        }
    }


    render() {

        let classNames = "locatebutton";

        if (this.state.watchLocation) {
            classNames += " locatebutton--searching";
        }

        return [
            <button
                type="button"
                key="locatebutton"
                className={classNames}
                onClick={this.toggleLocate}
            >
                <IconLocation />
            </button>,
            <div className="distanceFromHome" key="distanceFromHome">
                {this.state.distance && (
                    <p>Du er <strong>{this.state.distance} meter</strong> unna</p>
                )}
            </div>
        ];
    }
}

export default Location;
