import React from "react";

const IconHome = () => (
    <svg viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            d="M0 5.7L7.8 0h.4L16 5.7v.8h-1V16H9.5v-6h-3v6H1V6.5H0v-.8zm2-.2l6-4.4 6 4.4V15h-3.5V9h-5v6H2V5.5z"
            clipRule="evenodd"
        />
    </svg>
);

export default IconHome;
