import React from "react";

function Details({ place, togglePlace }) {
    let classNames = "details";

    if (place) {
        classNames += " details--expanded";
    }

    return (
        <div className={classNames}>
            {place && (
                <section>
                    <header className="details__header">
                        <h1 className="details__title">
                            <a
                                href={place.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {place.title}
                            </a>
                            {" "}
                            <span
                                className={"details__category " + place.category}
                            >
                                {place.category}
                            </span>
                        </h1>
                        <button
                            type="button"
                            className="details__close"
                            onClick={() => togglePlace(place.id)}

                        >
                            <svg viewBox="0 0 16 16">
                                <path d="M8.70725 8.00014L15.0003 1.70711L14.2932 1L8.00014 7.29304L1.70711 1L1 1.70711L7.29304 8.00014L1 14.2932L1.70711 15.0003L8.00014 8.70725L14.2932 15.0003L15.0003 14.2932L8.70725 8.00014Z" />
                            </svg>

                        </button>
                    </header>
                
                    <p className="details__desc">{place.desc}</p>

                </section>
            )}
        </div>
    );
}

export default Details;
