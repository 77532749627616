import React, { Component } from "react";

import Details from "./Details";
import List from "./List";
import Location from "./Location";
import MapLayers from "./MapLayers";

import IconHome from "./Icons/IconHome";
import IconFood from "./Icons/IconFood";
import IconPub from "./Icons/IconPub";
import IconMisc from "./Icons/IconMisc";

import "leaflet/dist/leaflet.css";
import L from "leaflet";

function compare(a, b) {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
}

class App extends Component {
    state = {
        map: null,
        takeMehome: false,
        homePosition: [53.4053811, -2.9777429],
        myPosition: false,
        places: [],
        category: null,
        place: null
    };

    updateData = json => {
        console.log(json);
        const places = json.map(place => {
            return {
                id: encodeURIComponent(place["title"]),
                title: place["title"],
                desc: place["desc"],
                lat: place["lat"],
                lng: place["lng"],
                category: place["category"],
                url: place["url"]
            };
        });

        this.setState({ places: places.sort(compare) });
    };

    toggleHome = () => {
        this.setState({
            takeMehome: !this.state.takeMehome
        });
    };

    toggleCategory = category => {
        this.setState(state => {
                if (state.category === category) {
                return { category: null };
            } else {
                return { category: category };
            }
        });
    };

    togglePlace = id => {
        this.setState(state => {
            if (state.place === id) {
                return { place: null };
            } else {
                return { place: id };
            }
        });
    };

    componentDidMount() {
        const map = L.map("mapcontainer", {
            attributionControl: false,
            zoomControl: false
        });

        this.setState({
            map: map
        });

        const URL =
            "https://opensheet.vercel.app/1BdUMaoUJNMXsutEOKMBHAem_fJUojRQJyhSnyCZ2zHc/Sheet1";

        fetch(URL)
            .then(response => {
                return response.json();
            })
            .then(json => {
                this.updateData(json);
            });
    }

    render() {
        let places = this.state.places;

        if (this.state.category) {
            places = this.state.places.filter(
                place => place.category === this.state.category
            );
        }

        if (this.state.takeMehome) {
            places = [];
        }

        let place = null;
        if (this.state.place) {
            this.state.places.forEach(item => {
                if (item.id === this.state.place) {
                    place = item;
                }
            });
        }

        return (
            <main className={this.state.takeMehome ? "takemehome-modus" : ""}>
                <div className="mapcontent" key="mapcontent">
                    <div
                        id="mapcontainer"
                        key="mapcontainer"
                        ref={div => {
                            this.mapcontainer = div;
                        }}
                    />
                    <MapLayers
                        map={this.state.map}
                        places={places}
                        place={place}
                        homePosition={this.state.homePosition}
                        togglePlace={this.togglePlace}
                    />
                    <Details place={place} togglePlace={this.togglePlace} />
                    <div className="mapoverlays">
                        <div className="list__top"></div>
                        <List
                            map={this.state.map}
                            places={places}
                            category={this.state.category}
                            togglePlace={this.togglePlace}
                        />
                    </div>
                </div>

                <div className="content" key="content">
                    <ul className="navigation">
                        <li className="navigation__listitem navigation__listitem--takemehome">
                            <button
                                type="button"
                                className={
                                    this.state.takeMehome
                                        ? "homebutton homebutton--takemehome"
                                        : "homebutton "
                                }
                                onClick={this.toggleHome}
                            >
                                <IconHome />
                            </button>
                        </li>
                        <li className="navigation__listitem navigation__listitem--locatebutton">
                            <Location
                                map={this.state.map}
                                homePosition={this.state.homePosition}
                            />
                        </li>
                        <li className="navigation__listitem">
                            <button
                                type="button"
                                className="navigation__link navigation__link--icon navigation__link--pub"
                                onClick={() => this.toggleCategory("pub")}
                            >
                                <IconPub />
                                Pub
                            </button>
                        </li>
                        <li className="navigation__listitem">
                            <button
                                type="button"
                                className="navigation__link navigation__link--icon navigation__link--mat"
                                onClick={() => this.toggleCategory("mat")}
                            >
                                <IconFood />
                                Mat
                            </button>
                        </li>
                        <li className="navigation__listitem">
                            <button
                                type="button"
                                className="navigation__link navigation__link--icon navigation__link--ymse"
                                onClick={() => this.toggleCategory("ymse")}
                            >
                                <IconMisc />
                                Ymse
                            </button>
                        </li>
                    </ul>
                </div>
            </main>
        );
    }
}

export default App;
