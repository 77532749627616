import React from "react";

const IconLocation = () => (
    <svg viewBox="0 0 16 16">
        <path d="M7.5 0H8.5V2H8H7.5V0Z" />
        <path d="M8 10.5C9.38071 10.5 10.5 9.38074 10.5 8C10.5 6.61926 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61926 5.5 8C5.5 9.38074 6.61929 10.5 8 10.5Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 8C14 4.68628 11.3137 2 8 2C4.68628 2 2 4.68628 2 8V7.5H0V8.5H2V8C2 11.3137 4.68628 14 8 14H7.5V16H8.5V14H8C11.3137 14 14 11.3137 14 8ZM3 8C3 5.23859 5.23859 3 8 3C10.7614 3 13 5.23859 13 8C13 10.7614 10.7614 13 8 13C5.23859 13 3 10.7614 3 8Z"
        />
        <path d="M14 8V7.5H16V8.5H14V8Z" />
    </svg>
);

export default IconLocation;
