import React from "react";

import IconFood from "./Icons/IconFood";
import IconPub from "./Icons/IconPub";
import IconMisc from "./Icons/IconMisc";

function List({ places, togglePlace, category, map }) {
    let classNames = "list";

    if (category) {
        classNames += " list--expanded";
    }

    const icons = {
        pub: <IconPub />,
        mat: <IconFood />,
        ymse: <IconMisc />
    };

    const listClick = place => {
        togglePlace(place.id);

        map.panTo([
            place.lat,
            place.lng
        ]);
    };

    return (
        <ul className={classNames}>
            {places.map(place => (
                <li key={place.id} className="list__listitem">
                    <button
                        type="button"
                        className={"list__link " + place.category}
                        onClick={() => listClick(place)}
                    >
                        {icons[place.category]}
                        {place.title}
                    </button>
                </li>
            ))}
        </ul>
    );
}

export default List;
